import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const gallery = new Array(12)
  .fill(undefined)
  .map((_, i) => `/gallery2/${i + 1}.jpg`);

const Portal = ({ children, containerType = "div", className }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return ReactDOM.createPortal(
    React.createElement(containerType, { className }, children),
    document.body
  );
};

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M10.166 8.813l7.179-7.18A.957.957 0 1 0 15.99.28L8.813 7.46 1.633.28A.957.957 0 1 0 .28 1.634l7.18 7.178-7.18 7.18a.957.957 0 1 0 1.354 1.353l7.179-7.18 7.178 7.18a.954.954 0 0 0 1.354 0 .957.957 0 0 0 0-1.354l-7.18-7.178"
    />
  </svg>
);

export function HomeGallery() {
  const [activeSrc, setActiveSrc] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setActiveSrc("");
      setIsClosing(false);
    }, 300);
  };

  console.log({ activeSrc, isClosing });

  return (
    <div className="home__gallery">
      <h2>Photo galleries</h2>
      <div className="home__gallery-photos">
        {gallery.map((src) => (
          <div
            className="home__gallery-photo"
            onClick={() => setActiveSrc(src)}
            key={src}
          >
            <img src={src} />
          </div>
        ))}
      </div>
      <div className="home__gallery-btns">
        <a
          href="https://www.facebook.com/Leeds-Holi-Colour-Festival-100972091831886"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn">FACEBOOK</button>
        </a>
        <a
          href="https://www.instagram.com/leedsholifestival2023/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn">INSTAGRAM</button>
        </a>
      </div>
      {activeSrc && (
        <Portal>
          <div
            className={`home__gallery-lightbox ${
              isClosing ? "home__gallery-lightbox--closing" : ""
            }`}
          >
            <div className="home__gallery-backdrop"></div>
            <div className="home__gallery-lightbox-img ">
              <img src={activeSrc} />
            </div>
            <div className="home__gallery-close" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
}
