import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import TicketShop from "components/TicketShop";
import { List } from "components/List";
import { Hero } from "components/Hero";
import { ticketShopScript } from "constants/ticketShopScript";
import { StaticImage } from "gatsby-plugin-image";
import HeroWavesSvg from "../svgs/HeroWaves.svg";
import HeroWavesFlippedSvg from "../svgs/HeroWavesFlipped.svg";
import RidesSvg from "../svgs/Rides.svg";
import ColorsSvg from "../svgs/Colors.svg";
import CutlerySvg from "../svgs/Cutlery.svg";
import { Video } from "components/Video";
import { HomeGallery } from "components/HomeGallery";

const staticImgProps = {
  alt: "",
  layout: "fullWidth",
  objectFit: "cover",
  objectPosition: "center",
};

const carouselImages = {
  1: {
    node: <StaticImage src="../images/1.jpg" {...staticImgProps} />,
    src: "/1.jpg",
  },
  2: {
    node: <StaticImage src="../images/2.jpg" {...staticImgProps} />,
    src: "/2.jpg",
  },
  3: {
    node: <StaticImage src="../images/3.jpg" {...staticImgProps} />,
    src: "/3.jpg",
  },
  4: {
    node: <StaticImage src="../images/4.jpg" {...staticImgProps} />,
    src: "/4.jpg",
  },
};

function IndexPage(p) {
  const title = "Leeds Holi Festival";
  const description = ``;

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        carouselImages={[
          carouselImages["1"],
          carouselImages["2"],
          carouselImages["3"],
          carouselImages["4"],
        ]}
        image={carouselImages["1"]}
      >
        <div className="home__hero-text">
          <h1>
            LEEDS
            <br />
            HOLI FESTIVAL
          </h1>
          <p>BRINGING CULTURES TOGETHER</p>
        </div>
      </Hero>
      <div className="waves">
        <HeroWavesSvg />
      </div>
      <div className="home__highlights">
        <h2>Highlights from 2023</h2>
        <div className="home__highlights-video-container-container">
          <div className="home__highlights-video-container">
            <div className="home__highlights-video">
              <Video
                youtubeVideoId="pUoekBrHJHs"
                previewSrc="/preview2.jpg"
                seekTo={0.2}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="home__highlights">
        <h2>Highlights from 2022</h2>
        <div className="home__highlights-video-container-container">
          <div className="home__highlights-video-container">
            <div className="home__highlights-video">
              <Video
                youtubeVideoId="aUvCfgUm7iQ"
                previewSrc="/preview.jpg"
                seekTo={1.2}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="waves waves--flipped">
        <HeroWavesFlippedSvg />
      </div>
      <div className="home__tagline">
        <h2>
          The biggest open
          <br />
          air colour festival in the uk
        </h2>
      </div>
      <div className="waves">
        <HeroWavesSvg />
      </div>
      <div id="tickets" className="home__ticketshop">
        <TicketShop scriptString={ticketShopScript} />
      </div>
      <div className="waves waves--flipped">
        <HeroWavesFlippedSvg />
      </div>
      <div className="home__headline">
        <div className="home__headline-columns">
          <img src="/vish.png" alt="Vish" />
          <div>
            <h4>HEADLINING</h4>
            <h2>VISH</h2>
            <p>
              Vish is a singer/musician based in London, England. He is known
              for his busking on the streets of the UK and performing with
              Bollywood rapper Badshah, anchor/actor Maniesh Paul & playback
              singer Ash King. His Instagram has over 400k followers & TikTok
              has over 300k. Vish has gained over 100 million views on his reels
              in just a year and his videos were published multiple times on
              major Indian news channels.
            </p>
          </div>
        </div>
        <div className="home__headline-columns">
          <img src="/jaz-dhami.png" alt="Jaz Dhami" />
          <div>
            <h4>HEADLINING</h4>
            <h2>JAZ DHAMI</h2>
            <p>
              Jaz is a British Indian singer, songwriter, and producer from
              Birmingham. He took the world by storm with his song High Heels.
              Catch him live on 23rd March at the Leeds Holi Festival.
            </p>
          </div>
        </div>
      </div>
      <div className="waves">
        <HeroWavesSvg />
      </div>
      <div className="home__teasers">
        <h2>MORE AT THE FESTIVAL</h2>
        <div className="home__teasers-grid">
          <div key="1" className="home__teaser-container">
            <div key="1" className="home__teaser">
              <RidesSvg />
              <h3>Fun rides</h3>
              <p>
                Let customers buy tickets with one click, without leaving your
                website.
              </p>
            </div>
          </div>
          <div key="2" className="home__teaser-container">
            <div key="2" className="home__teaser">
              <ColorsSvg />
              <h3>Colour stations</h3>
              <p>
                Enable group tickets to be transferred to friends arriving at
                different times.
              </p>
            </div>
          </div>
          <div key="3" className="home__teaser-container">
            <div key="3" className="home__teaser">
              <CutlerySvg />
              <h3>Food stalls</h3>
              <p>
                Allow for rapid check-in on the door and reduce queue times with
                our entry manager app.
              </p>
            </div>
          </div>
        </div>
      </div>
      <HomeGallery />
      <div className="home__sponsors">
        <h2>OUR SPONSORS</h2>
        <div className="home__sponsors-grid">
          <img src="/sponsor-amber-cars.png" />
          <img src="/sponsor-moc.png" />
          <img src="/sponsor-isf.png" />
          <img src="/sponsor-krystall.png" />
        </div>
      </div>
      <div className="waves waves--flipped">
        <HeroWavesFlippedSvg />
      </div>
    </React.Fragment>
  );
}

export default IndexPage;
